export const config = {
  port: process.env.SERVER_PORT,
  browserSyncPort: process.env.BROWSER_SYNC_PORT,
  api: process.env.API,
  sessionRedisDb: process.env.SESSION_REDIS_DB,
  production: process.env.PRODUCTION === 'true',
  localhost: process.env.LOCALHOST === 'true',
  withStatic: process.env.WITH_STATIC === 'true',
  withRedis: process.env.WITH_REDIS === 'true',
  inspect: process.env.INSPECT === 'true',
  logger: process.env.LOGGER === 'true',
  auth: {
    basePath: process.env.AUTH_BASE_PATH,
  },
  databaseUrl: process.env.DATABASE_URL,
  sentryDsn: process.env.SENTRY_DSN,
  proxy: process.env.PROXY,
  connectWalletId: process.env.CW_PROJECT_ID,
  redisHost: process.env.REDIS_HOST,
};
