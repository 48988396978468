import { StyleProvider } from '@ant-design/cssinjs';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Configuration, FrontendApi } from '@ory/client';
import * as Sentry from '@sentry/react';
import { ConfigProvider, Layout, theme } from 'antd';
import classNames from 'classnames';
import { config } from 'config';
import fetch from 'cross-fetch';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useAnalytics } from 'shared/analytics';
import GlobalStyle from 'shared/GlobalStyles';
import { coingeckoCoinList } from 'shared/recoil/atoms/coingeckoTokensInfo';

// eslint-disable-next-line no-restricted-imports
import { userSelector } from 'shared/recoil/selectors/user';

import coinGeckoCoinsList from '../../constants/coingeckoCoinsList.json';

import './App.scss';
import { VisibilityWatcher } from './components/common/VisibilityWatcher';
import { AppContent } from './layouts/AppContent/AppContent';

// eslint-disable-next-line import/order
import { NO_AUTH_TOKEN } from 'shared/api/useNoAuthToken';
import type { User } from 'types';

import { themeAtom } from 'shared/recoil/atoms/theme';

import { useLocation } from 'react-router-dom';

import { selectedCurrencyAtom } from 'shared/recoil/atoms/currency';

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem(NO_AUTH_TOKEN);

  return {
    headers: {
      ...headers,
      'X-Demo-Token': token,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(new HttpLink({ uri: '/graphql', fetch })),
  cache: new InMemoryCache(),
  ssrMode: false,
});

const ory = new FrontendApi(
  new Configuration({
    basePath: config.auth.basePath,
    baseOptions: {
      withCredentials: true,
    },
  }),
);
export const App = () => {
  const [selectedCurrency, setSelectedCurrency] = useRecoilState(selectedCurrencyAtom);
  const [user, setUser] = useRecoilState(userSelector);
  const setCoingeckoCoinList = useSetRecoilState(coingeckoCoinList);
  const [appTheme, setTheme] = useRecoilState(themeAtom);
  const { pathname, search } = useLocation();
  useAnalytics(user?.id);
  useEffect(() => {
    setCoingeckoCoinList(coinGeckoCoinsList as never);
    const identify = async (): Promise<User | null> => {
      try {
        const {
          data: {
            identity: {
              id,
              traits: { email },
            },
          },
        } = await ory.toSession({});
        const {
          data: { logout_url: logoutUrl },
        } = await ory.createBrowserLogoutFlow({});

        setUser({
          email,
          logoutUrl,
          id,
        });

        return {
          email,
          logoutUrl,
          id,
        };
      } catch (error) {
        console.error(error, 'error');
        return null;
      }
    };

    if (document?.domain !== 'xtracker.microservices-staging.int.eks.pwlnh.com') {
      identify();
    }
    if (localStorage.theme) {
      setTheme(localStorage.theme);
    }

    const localStorageCurrency = localStorage.getItem('selectedCurrency');
    if (localStorageCurrency) {
      setSelectedCurrency(localStorageCurrency);
    }
  }, []);

  useEffect(() => {
    localStorage.theme = appTheme;
  }, [appTheme]);

  return (
    <>
      <GlobalStyle />
      <Sentry.ErrorBoundary
        onError={error => {
          Sentry.captureException(error);
        }}
        fallback={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}
          >
            <h1>Something went wrong.</h1>

            <a href="/">Go to home page</a>
          </div>
        }
      >
        <ApolloProvider client={client}>
          <StyleProvider ssrInline>
            <ConfigProvider
              componentSize="small"
              space={{ size: 'small' }}
              theme={{
                algorithm:
                  pathname === '/' || appTheme === 'white'
                    ? theme.defaultAlgorithm
                    : theme.darkAlgorithm,
                token: {
                  colorPrimary: '#296DF1',
                },
                components: {
                  Message: {
                    paddingXS: 12,
                    paddingSM: 12,
                    fontSize: 16,
                    fontSizeLG: 16,
                  },
                  Input: {
                    borderRadiusLG: 12,
                    colorBgContainerDisabled: '#F3F3F3',
                    colorBorder: '#E6E6E6',
                    colorError: '#EB1764',
                    colorErrorBorder: '#EB1764',
                    controlHeightLG: 52,
                    paddingLG: 17,
                    paddingXL: 17,
                    padding: 17,
                    paddingMD: 17,
                    fontSizeLG: 17,
                    colorTextPlaceholder: '#A6A6A6',
                    lineHeight: 0,
                    controlOutline: '#E8EBFD',
                  },
                  Card: {
                    borderRadiusLG: 32,
                    paddingLG: 32,
                  },
                  Tag: {
                    borderRadiusSM: 16,
                    colorBorder: 'transparent',
                    colorTextDescription: '#213DF0',
                    fontSize: 15,
                    fontSizeSM: 15,
                    lineHeight: 2,
                    paddingXXS: 4,
                  },
                  Button: {
                    colorBgContainer: '#E8EBFD',
                    padding: 16,
                    lineWidth: 0,
                    colorPrimary: '#2D48F1',
                    borderRadiusLG: 12,
                    colorLink: '#213DF0',
                    // colorIconHover: '#69b1ff',
                  },
                  Modal: {
                    borderRadiusLG: 16,
                    borderRadiusSM: 16,
                    padding: 20,
                    paddingLG: 20,
                    paddingMD: 20,
                    paddingXS: 20,
                    paddingContentHorizontalLG: 32,
                  },
                  Segmented: {
                    borderRadiusLG: 7,
                    borderRadius: 7,
                    borderRadiusSM: 7,
                    borderRadiusXS: 7,
                    fontSizeLG: 17,
                    fontSize: 17,
                    colorTextDisabled: '#868686',
                  },
                  Steps: {
                    colorFillContent: 'transparent',
                    colorBorderSecondary: '#ECECEC',
                    colorBorderBg: '#ECECEC',
                    colorSplit: '#ECECEC',
                    colorBorder: '#ECECEC',
                  },
                  Typography: {
                    fontSizeHeading1: 48,
                    fontSizeHeading2: 36,
                  },
                },
              }}
            >
              <Layout
                style={{ alignItems: 'center' }}
                className={classNames(pathname === '/' ? 'white' : appTheme)}
              >
                <VisibilityWatcher />
                <AppContent />
              </Layout>
            </ConfigProvider>
          </StyleProvider>
        </ApolloProvider>
      </Sentry.ErrorBoundary>
    </>
  );
};
