// eslint-disable-next-line import/order
import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/order
import { BrowserTracing } from '@sentry/browser';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { App } from 'shared/App';

import './i18n';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://66f39f99a8484dd6a49c40d438c2da6a@s.3commas.io/37',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0, // Adjust the sample rate for performance tracing
    // other options, such as release or environment
    environment: 'production',
  });
}

console.log(process.env.NODE_ENV, 'process.env.NODE_ENV');

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <RecoilRoot>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </RecoilRoot>,
);
